.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    color: white;
    width: 100%;
}

.header .logo {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    width: 100px;
    opacity: 1;
}

.header .navbar {
    display: flex;
    flex-direction: row;
    gap: 10px;
    list-style: none;
    border-radius: 8px; /* Add rounded corners */
    padding: 10px 0;
    margin: 20px 40px;
}

.navItems .showNavbar {
    display: none;
}

.header .navItems .navbar .navItem {
    color: #fff;
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
    padding: 12px 20px; /* Updated padding for better readability */

    transition: transform 0.3s ease;
}

.navItem a {
    text-decoration: none;
    color: inherit; /* Ensure consistent text color */
}

.header .navbar .navItem:hover {
    border-bottom: 2px solid white;
}

.header .navbar .navItem.active {
    border-bottom: 2px solid white;
}

@media screen and (max-width: 700px) {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    .header .navItems {
        display: flex;
        flex-direction: column;
        width: 100%; /* Ensures proper alignment */
    }

    .header .navItems .navbar {
        display: none; /* Default state */
        flex-direction: column;
        width: 100%; /* Full width */
        margin: 0;
        gap: 0;
    }

    .header .navItems .navbar.active {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .header .navItems .navItem {
        justify-content: center;
    }

    .header .navItems .navItem.active {
        color: #fff;
        box-shadow: none;
    }

    .header .navItems .showNavbar {
        display: flex;
        justify-content: center;
        cursor: pointer; /* Improves UX */
        margin: 0 0 10px 0;
    }

    .header .navItems .showNavbar .navImage {
        width: 30px;
        height: 20px;
    }
}

