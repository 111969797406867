.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #fff;
}

.welcome {
    margin: 20px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.welcome .title {
    font-weight: bold;
    font-size: 52px;
    text-shadow: 12px 12px 12px rgba(0, 0, 0, 0.2);
}

a {
    text-decoration: none;
}

.icon {
    height: 64px;
    width: 64px;
    border-radius: 50%;
}

.kenia {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    transform: scale(0.75);
}

.link, .item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 32px;
    margin: 10px;
    padding: 5px;
    transition: transform 0.2s ease-in-out;
}

.link:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
}

.inactive {
    display: none;
}

.iconTextContainer {
    color: #fff;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex: 1;
}

.iconText {
    display: inline-block;
    font-size: 18px;
    text-decoration: none;
    font-weight: bold;
    margin-left: -64px;
}

.description {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin: 0 0 10px 0;
}

@media screen and (max-width: 700px) {
    .welcome {
        margin: 0;
    }

    .link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 2px solid white;
        border-radius: 64px;
        margin: 10px;
        padding: 5px;
    }

    .iconText {
        font-size: 12px;
        font-weight: bold;
    }
}