.footer {
    margin-top: auto;
    width: 100%;
    color: white;
    text-align: center;
    bottom: 0;
    position: relative;
}

@media screen and (max-width: 700px) {

}

